import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Theme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import theme from '../../theme';

const useStyles = makeStyles(
    () => ({
        ctaButton: {
            position: 'relative',
            ...theme.button.purchase,
            flexGrow: 0,
            width: '100%',
            padding: '16px 8px',
            fontSize: 12,
            color: theme.palette.primary.background,
            height: 'fit-content',
            // @ts-expect-error
            [theme.breakpoints?.up('sm') as string]: {
                padding: '20px 8px',
                letterSpacing: 2,
            },
            '&.small': {
                padding: '12px 14px',
                letterSpacing: '2px',
                fontSize: '10px',
            },
            '&.x-small': {
                padding: '8px 7px',
                letterSpacing: '2px',
                fontSize: '10px',
                minWidth: 40,
                height: 40,
            },
            '&.bg-standard': {
                backgroundColor: '#041e3a',
                color: 'white',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#01011d',
                },
            },
            '&.bg-light': {
                backgroundColor: '#eaeff2',
                color: '#333',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#f7f9fa',
                },
            },
            '&.bg-wedding': {
                backgroundColor: '#a39161',
                color: 'white',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#c2af7b',
                },
            },
            '&.bg-wedding-fresh': {
                backgroundColor: '#DFF2F0',
                color: '#333',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#DFF2F0',
                    opacity: 0.8,
                },
            },
            '&.bg-white': {
                backgroundColor: 'white',
                color: 'black',
                '&:after, &:before': {
                    backgroundColor: 'black',
                },
                '&:hover': {
                    backgroundColor: '#ffffff',
                },
            },
            '&.remove-lines': {
                '&:after, &:before': {
                    display: 'none',
                    backgroundColor: 'transparent',
                },
            },
            /**
             * Globals
             */
            '&:before': {
                content: '""',
                height: 1,
                backgroundColor: theme.button.purchasePsuedo.background,
                display: 'block',
                position: 'absolute',
                top: 4,
                left: 0,
                right: 0,
                transition: 'all 0.25s ease',
            },
            '&:after': {
                content: '""',
                height: 1,
                backgroundColor: theme.button.purchasePsuedo.background,
                display: 'block',
                position: 'absolute',
                bottom: 4,
                left: 0,
                right: 0,
                transition: 'all 0.25s ease',
            },
            '&:hover': {
                '&:before, &:after': {
                    left: 4,
                    right: 4,
                },
            },
        },
        label: {
            flexDirection: 'column',
        },
        labelRightAlign: {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        labelLeftAlign: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        ctaButtonDisabled: {
            color: `${theme.button.purchaseDisabled.color} !important`,
            opacity: 0.4,
        },
        soldOut: {
            width: '100%',
            backgroundColor: theme.button.purchaseDisabled.backgroundColor,
            '&:hover': {
                backgroundColor: theme.button.purchaseDisabled.backgroundColor,
            },
            '&.success': {
                backgroundColor: '#0e6853',
            },
        },
        weddingListAdd: {
            maxHeight: 58,
            marginBottom: 10,
            backgroundColor: '#a39161',
        },
        spinnyThing: {
            position: 'absolute',
            right: '1rem',
            '.small &': {
                transform: 'scale(0.18)',
                right: '0.5rem',
            },
        },
        doneIcon: {
            position: 'absolute',
            right: '1rem',
            '.small &': {
                transform: 'scale(0.8)',
                right: '0.5rem',
            },
        },
        hasIcon: {
            '& > span:first-child': {
                display: 'grid',
                gridTemplateColumns: '16px 1fr',
                gap: 10,
                alignItems: 'center',
                justifyContent: 'center',
                width: 'fit-content',
            },
            ' & svg': {
                height: 16,
                width: 16,
                fontSize: 16,
            }
        },
    }),
    { name: 'cta-button' },
);

interface CallToActionButtonProps extends Omit<React.ComponentProps<typeof Button>, 'size'> {
    size?: 'small' | 'normal' | 'x-small';
    colorScheme?: 'bg-white' | 'bg-wedding' | 'bg-wedding-fresh' | 'bg-standard' | 'bg-light';
    addLines?: boolean;
    isLoading?: boolean;
    isDone?: boolean;
    show?: boolean;
    className?: string;
    labelPosition?: 'left' | 'right';
    icon?: JSX.Element;
}

export default function CallToActionButton(
    {
        onClick,
        disabled,
        href,
        children,
        size = 'normal',
        colorScheme = 'bg-standard',
        addLines = false,
        isLoading = false,
        isDone = false,
        style,
        show = true,
        labelPosition,
        className,
        icon,
        ...props
    }: CallToActionButtonProps,
): JSX.Element | null {
    const classes = useStyles();
    const router = useRouter();
    const removeLinesClass = addLines ? null : 'remove-lines';

    if (!show) {
        return null;
    }

    function renderLoading(isLoading: boolean, isDone: boolean) {
        if (!isLoading) {
            return renderDone(isDone);
        }

        return (
            <CircularProgress
                className={`${classes.spinnyThing} ${colorScheme}`}
                size={18}
                style={{ marginLeft: 6 }}
            />
        );
    }

    function renderDone(isDone: boolean) {
        if (!isDone) {
            return null;
        }

        return <>
            {/** @ts-ignore */}
            <CheckCircle
                className={classes.doneIcon}
                size={18}
                style={{ marginLeft: 6 }}
            />
        </>
    }

    function resolveLabelClass(labelPosition: CallToActionButtonProps['labelPosition']) {
        if (labelPosition === 'right') {
            return classes.labelRightAlign;
        }

        if (labelPosition === 'left') {
            return classes.labelLeftAlign;
        }

        return classes.label;
    }

    function middleware(component: JSX.Element) {
        if (href) {
            const originalClick = onClick || function() {};
            onClick = async (event) => {
                await originalClick(event);
                await router.push(href);
            }

            // @ts-ignore
            return <a href={href} onClick={onClick}>{component}</a>;
        }

        return component;
    }

    const activeClasses: Array<string | null> = [
        classes.ctaButton,
        size,
        colorScheme,
        removeLinesClass,
        className ? className : '',
        props?.startIcon ? classes.hasIcon : '',
    ];

    return middleware(
        <Button
            {...props}
            style={style}
            className={activeClasses.join(' ')}
            onClick={onClick}
            classes={{
                label: resolveLabelClass(labelPosition),
                disabled: classes.ctaButtonDisabled,
            }}
            disabled={disabled}
        >
            {children}
            {renderLoading(isLoading, isDone)}
        </Button>
    );
}
